import { Link } from "react-router-dom";
import ShowPostProfile from "./ShowPostProfile";
import InteractionMenu from "./InteractionMenu";
import styles from "./ShowPost.module.css";

function ShowPost({ value, showCommunity = true }) {
  const {
    id,
    title,
    content,
    writer,
    affiliation,
    recommended,
    createdAt,
    comment,
  } = value;
  const profile = { writer, affiliation };

  return (
    <div>
      <ShowPostProfile
        showCommunity={showCommunity}
        profile={profile}
        time={createdAt}
      />
      <div className={styles.texts}>
        <Link to={`/nest/${affiliation}/post/${id}`}>
          <h2>{title}</h2>
        </Link>
        <p className={styles.post}>{content}</p>
      </div>
      <InteractionMenu
        recommendCount={recommended}
        commentCount={comment.length}
      />
    </div>
  );
}

export default ShowPost;
