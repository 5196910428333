// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TabContent_sectionTitle__4XdKF {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 1rem;
}

.TabContent_havingBadge__J36PD {
  margin-bottom: 50px;
}

.TabContent_notHavingBadge__vfzDa {
  position: relative;
  width: 100%;
  height: 100%;
}

.TabContent_locked__LFnhG {
  position: absolute;
  top: calc(50% - 25px);
  right: calc(50% - 25px);
  width: 50px;
  height: 50px;
  background-image: url("https://cdn.icon-icons.com/icons2/621/PNG/512/lock-padlock-symbol-for-protect_icon-icons.com_56926.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.TabContent_badges__Nlmw5 {
  filter: blur(5px);
  width: 100%;
  height: 100%;
}

.TabContent_badge__3\\+ptO {
  width: 100px;
  height: 100px;
  margin: 10px;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/TabContent.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,+HAA+H;EAC/H,2BAA2B;EAC3B,4BAA4B;EAC5B,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".sectionTitle {\n  font-size: 1.5rem;\n  font-weight: 600;\n  color: #1f2937;\n  margin-bottom: 1rem;\n}\n\n.havingBadge {\n  margin-bottom: 50px;\n}\n\n.notHavingBadge {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n\n.locked {\n  position: absolute;\n  top: calc(50% - 25px);\n  right: calc(50% - 25px);\n  width: 50px;\n  height: 50px;\n  background-image: url(\"https://cdn.icon-icons.com/icons2/621/PNG/512/lock-padlock-symbol-for-protect_icon-icons.com_56926.png\");\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  z-index: 1;\n}\n\n.badges {\n  filter: blur(5px);\n  width: 100%;\n  height: 100%;\n}\n\n.badge {\n  width: 100px;\n  height: 100px;\n  margin: 10px;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionTitle": `TabContent_sectionTitle__4XdKF`,
	"havingBadge": `TabContent_havingBadge__J36PD`,
	"notHavingBadge": `TabContent_notHavingBadge__vfzDa`,
	"locked": `TabContent_locked__LFnhG`,
	"badges": `TabContent_badges__Nlmw5`,
	"badge": `TabContent_badge__3+ptO`
};
export default ___CSS_LOADER_EXPORT___;
