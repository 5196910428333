// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShowPost_post__K413X {
  white-space: pre-wrap;
  line-height: 1.5;
}
`, "",{"version":3,"sources":["webpack://./src/components/ShowPost.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gBAAgB;AAClB","sourcesContent":[".post {\n  white-space: pre-wrap;\n  line-height: 1.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": `ShowPost_post__K413X`
};
export default ___CSS_LOADER_EXPORT___;
