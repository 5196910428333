// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/icon--up-hover.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/icon--down-hover.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecommendCount_recommended__IZPQY > p {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 3px;
  margin: 0;
}

.RecommendCount_imgBox__CC3NC {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.RecommendCount_imgBox__CC3NC:hover {
  background-color: rgb(200, 200, 200);
}

.RecommendCount_upIcon__lAVVk:hover {
  content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.RecommendCount_downIcon__OxfCd:hover {
  content: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
`, "",{"version":3,"sources":["webpack://./src/components/RecommendCount.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,cAAc;EACd,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,gDAA0C;AAC5C;;AAEA;EACE,gDAA4C;AAC9C","sourcesContent":[".recommended > p {\n  width: fit-content;\n  padding: 0 3px;\n  margin: 0;\n}\n\n.imgBox {\n  width: 30px;\n  height: 30px;\n  border-radius: 15px;\n}\n\n.imgBox:hover {\n  background-color: rgb(200, 200, 200);\n}\n\n.upIcon:hover {\n  content: url(../assets/icon--up-hover.svg);\n}\n\n.downIcon:hover {\n  content: url(../assets/icon--down-hover.svg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recommended": `RecommendCount_recommended__IZPQY`,
	"imgBox": `RecommendCount_imgBox__CC3NC`,
	"upIcon": `RecommendCount_upIcon__lAVVk`,
	"downIcon": `RecommendCount_downIcon__OxfCd`
};
export default ___CSS_LOADER_EXPORT___;
