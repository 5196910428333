import { useState } from "react";
import { useLocation } from "react-router-dom";
import { getUserInformation } from "../api";
import NotFoundPage from "./NotFoundPage";
import ShowCommunityProfile from "../components/ShowCommunityProfile";
import ReadingOption from "../components/ReadingOptions";
import TabContent from "../components/TabContent";
import pageStyle from "../components/ui/PageBox.module.css";

function UserPage() {
  const [activeTab, setActiveTab] = useState("entire");
  const location = useLocation().pathname;
  const userID = location.split("/")[2];
  const { name, followers, followings, point, badges } =
    getUserInformation(userID);
  if (name === "error") {
    return (
      <NotFoundPage
        variant="big"
        title="존재하지 않는 유저입니다"
        description="올바른 주소가 맞는지 다시 한 번 확인해 주세요"
      />
    );
  }

  return (
    <div className={pageStyle.pageBox}>
      <ShowCommunityProfile title={name} location={location} />
      <ReadingOption
        isUser={true}
        activeTab={activeTab}
        handleClick={setActiveTab}
      />
      <TabContent activeTab={activeTab} badges={badges} />
    </div>
  );
}

export default UserPage;
