// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PostPage_comment__n1DsY {
  margin-bottom: 25px;
}

.PostPage_comment__n1DsY:not(:last-of-type) {
  border-bottom: var(--border-box);
}

.PostPage_comment__n1DsY > p {
  margin-top: 0;
  margin-left: 45px;
}

.PostPage_communityBox__1jxyU {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;
  margin-top: 2px;
  margin-left: 10px;
}

.PostPage_imgBox__ZwG-Z {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background-color: rgb(200, 200, 200);
}

.PostPage_imgBox__ZwG-Z:hover {
  background-color: rgb(180, 180, 180);
}

.PostPage_community__fOdB- {
  margin: 0;
  line-height: 30px;
  vertical-align: top;
  margin-top: 1px;
  margin-left: 15px;
}

.PostPage_postBox__eAZG5 {
  width: 50vw;
  padding: 10px;
  padding-top: 0;
}

.PostPage_commentBox__8Cslt {
  width: 50vw;
  border-top: var(--border-line);
  padding: 5px 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/PostPage.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,SAAS;EACT,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,8BAA8B;EAC9B,iBAAiB;AACnB","sourcesContent":[".comment {\n  margin-bottom: 25px;\n}\n\n.comment:not(:last-of-type) {\n  border-bottom: var(--border-box);\n}\n\n.comment > p {\n  margin-top: 0;\n  margin-left: 45px;\n}\n\n.communityBox {\n  display: flex;\n  align-items: center;\n  width: fit-content;\n  height: 40px;\n  margin-top: 2px;\n  margin-left: 10px;\n}\n\n.imgBox {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 30px;\n  height: 30px;\n  border-radius: 20px;\n  background-color: rgb(200, 200, 200);\n}\n\n.imgBox:hover {\n  background-color: rgb(180, 180, 180);\n}\n\n.community {\n  margin: 0;\n  line-height: 30px;\n  vertical-align: top;\n  margin-top: 1px;\n  margin-left: 15px;\n}\n\n.postBox {\n  width: 50vw;\n  padding: 10px;\n  padding-top: 0;\n}\n\n.commentBox {\n  width: 50vw;\n  border-top: var(--border-line);\n  padding: 5px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comment": `PostPage_comment__n1DsY`,
	"communityBox": `PostPage_communityBox__1jxyU`,
	"imgBox": `PostPage_imgBox__ZwG-Z`,
	"community": `PostPage_community__fOdB-`,
	"postBox": `PostPage_postBox__eAZG5`,
	"commentBox": `PostPage_commentBox__8Cslt`
};
export default ___CSS_LOADER_EXPORT___;
