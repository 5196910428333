// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MentorListPage_specialists__MI\\+Ap {
  width: 50vw;
  margin-top: 30px;
}

.MentorListPage_list__GYinI {
  margin-top: 30px;
}

.MentorListPage_container__vhi8P {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.MentorListPage_container__vhi8P:hover {
  background-color: rgb(240, 240, 240);
}

.MentorListPage_container__vhi8P:not(:last-child) {
  margin-bottom: 20px;
}

.MentorListPage_img__UAXwk {
  width: 50px;
  height: 50px;
}

.MentorListPage_textContainer__wGY7u {
  display: flex;
  flex-direction: column;
}

.MentorListPage_detailsContainer__G6wUd {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.MentorListPage_detailsContainer__G6wUd > p {
  margin: 0;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MentorListPage.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,SAAS;EACT,eAAe;AACjB","sourcesContent":[".specialists {\n  width: 50vw;\n  margin-top: 30px;\n}\n\n.list {\n  margin-top: 30px;\n}\n\n.container {\n  width: 100%;\n  height: 70px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 20px;\n}\n\n.container:hover {\n  background-color: rgb(240, 240, 240);\n}\n\n.container:not(:last-child) {\n  margin-bottom: 20px;\n}\n\n.img {\n  width: 50px;\n  height: 50px;\n}\n\n.textContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.detailsContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 20px;\n}\n\n.detailsContainer > p {\n  margin: 0;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"specialists": `MentorListPage_specialists__MI+Ap`,
	"list": `MentorListPage_list__GYinI`,
	"container": `MentorListPage_container__vhi8P`,
	"img": `MentorListPage_img__UAXwk`,
	"textContainer": `MentorListPage_textContainer__wGY7u`,
	"detailsContainer": `MentorListPage_detailsContainer__G6wUd`
};
export default ___CSS_LOADER_EXPORT___;
