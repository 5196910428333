import { useLocation } from "react-router-dom";
import PostList from "../components/PostList";
import { getCommunityInformation } from "../api";
import NotFoundPage from "./NotFoundPage";
import ShowCommunityProfile from "../components/ShowCommunityProfile";

function CommunityPage() {
  const location = useLocation().pathname;
  const communitySlug = location.split("/")[2];
  const { title } = getCommunityInformation(communitySlug);
  if (title === "error") {
    return (
      <NotFoundPage
        variant="big"
        title="존재하지 않는 커뮤니티입니다"
        description="올바른 주소가 맞는지 다시 한 번 확인해 주세요"
      />
    );
  }

  return (
    <div>
      <ShowCommunityProfile title={title} location={location} />
      <PostList
        isCommunity={true}
        showCommunity={false}
        keyword={communitySlug}
      />
    </div>
  );
}

export default CommunityPage;
