import { useState } from "react";
import styles from "./PostingPage.module.css";

function PostingPage() {
  const INITIAL_VALUES = {
    title: "",
    content: "",
  };

  const [values, setValues] = useState(INITIAL_VALUES);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>포스팅</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="title" className={styles.label}>
            제목
          </label>
          <input
            type="text"
            id="title"
            className={styles.input}
            required
            placeholder="제목을 입력하세요"
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="content" className={styles.label}>
            내용
          </label>
          <textarea
            id="content"
            className={styles.textarea}
            required
            placeholder="내용을 입력하세요"
            rows="10"
            onChange={handleChange}
          ></textarea>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="file-upload" className={styles.label}>
            이미지 첨부
          </label>
          <input
            type="file"
            id="file-upload"
            accept="image/*"
            className={styles.fileInput}
          />
          <button
            type="button"
            onClick={() => document.getElementById("file-upload").click()}
            className={styles.fileButton}
            disabled={true}
          >
            이미지 선택
          </button>
          <span className={styles.fileName}>선택된 파일 없음</span>
        </div>
        <button type="submit" className={styles.button}>
          글 작성
        </button>
      </form>
    </div>
  );
}

export default PostingPage;
