import { AUTH_URL as base, formingData } from "./ApiMain";

export async function login(userData) {
  const res = await fetch(`${base}/login`, {
    method: "POST",
    body: formingData(userData),
    credentials: "include",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, msg, ...ok } = res;
  const user = ok && ok.data.user;

  return { code, msg, user };
}

export async function register(userData) {
  const res = await fetch(`${base}/register`, {
    method: "POST",
    body: formingData(userData),
    credentials: "include",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, msg } = res;

  return { code, msg };
}

export async function verify_email(userData) {
  const res = await fetch(`${base}/verify-email`, {
    method: "POST",
    body: formingData(userData),
    credentials: "include",
  }).then((body) => body.json());

  if (!res) {
    return { code: 404, msg: "주소가 잘못되었습니다" };
  }

  const { code, msg } = res;

  return { code, msg };
}
