import { useRef, useState } from "react";
import closeIcon from "../assets/icon--close-button.svg";
import styles from "./Login.module.css";
import classNames from "classnames/bind";
import { useAuth } from "../contexts/AuthProvider";
import { Link } from "react-router-dom";
const bindCN = classNames.bind(styles);

function Login() {
  const { setLogIn } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const modalBackground = useRef();
  const INITIAL_VALUES = {
    email: "",
    password: "",
  };
  const [values, setValues] = useState(INITIAL_VALUES);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLogIn(values);
    setValues((prevValues) => ({ ...prevValues, ...INITIAL_VALUES }));
  };

  return (
    <>
      <button className={styles.loginButton} onClick={() => setIsOpen(true)}>
        <h2>로그인</h2>
      </button>
      {isOpen && (
        <div
          className={styles.modalContainer}
          ref={modalBackground}
          onClick={(e) => {
            if (e.target === modalBackground.current) {
              setIsOpen(false);
            }
          }}
        >
          <div className={styles.modalContent}>
            <div className={styles.modalHead}>
              <p className={styles.login}>로그인</p>
              <div
                className={styles.closePopup}
                onClick={() => setIsOpen(false)}
              >
                <img src={closeIcon} alt="닫기" />
              </div>
            </div>
            <div className={styles.modalBody}>
              <p>로그인하면 더 많은 기능을 이용하실 수 있어요!</p>
              <form className={styles.formContainer} onSubmit={handleSubmit}>
                <div className={bindCN(styles.inputContainer, styles.email)}>
                  <label>
                    <div className={styles.inputLabelContainer}>
                      <div className={styles.inputLabel}>이메일</div>
                    </div>
                    <input
                      type="text"
                      name="email"
                      inputMode="email"
                      autoComplete="off"
                      className={styles.input}
                      onChange={handleChange}
                    />
                  </label>
                </div>
                <div className={bindCN(styles.inputContainer, styles.password)}>
                  <label>
                    <div className={styles.inputLabelContainer}>
                      <div className={styles.inputLabel}>비밀번호</div>
                    </div>
                    <input
                      type="password"
                      name="password"
                      autoComplete="off"
                      className={styles.input}
                      onChange={handleChange}
                    />
                  </label>
                </div>
                <div>
                  <button type="submit" className={styles.submitButton}>
                    <div className={styles.submitLabel}>로그인 하기</div>
                  </button>
                </div>
              </form>
              <div className={styles.signInMenuContainer}>
                <div className={styles.oauthSignInContainer}>
                  <p>SNS 간편 로그인 / 가입</p>
                  <div
                    className={bindCN(styles.iconContainer, styles.google)}
                  ></div>
                  <div
                    className={bindCN(styles.iconContainer, styles.kakao)}
                  ></div>
                </div>
                <div className={styles.alignRight}>
                  <Link to={"/signin"}>
                    <p>이메일로 가입하기</p>
                  </Link>
                </div>
                <div className={styles.alignRight}>
                  <Link to={"/forgotpassword"}>
                    <p>비밀번호 찾기</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
