import { memo } from "react";
import styles from "./RecommendCount.module.css";
import upIcon from "../assets/icon--up.svg";
import downIcon from "../assets/icon--down.svg";

const RecommendCount = memo(function RecommendCount({ recommendCount }) {
  return (
    <div className={styles.recommended}>
      <div className={styles.imgBox}>
        <img className={styles.upIcon} src={upIcon} alt="추천" />
      </div>
      <p>{recommendCount}</p>
      <div className={styles.imgBox}>
        <img className={styles.downIcon} src={downIcon} alt="비추천" />
      </div>
    </div>
  );
});

export default RecommendCount;
