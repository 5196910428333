import styles from "./ShowPostProfile.module.css";
import DateText from "./DateText";
import pf from "../assets/logo.png";
import etc from "../assets/icon--etc.svg";
import { getCommunityTitle } from "../api";
import { Link } from "react-router-dom";

function ShowPostProfile({ showCommunity = true, profile, time }) {
  const communityTitle = getCommunityTitle(profile.affiliation);

  return (
    <div className={styles.profileContainer}>
      {showCommunity ? (
        <div className={styles.profile}>
          <img src={pf} className={styles.img} alt="커뮤니티 이미지" />
          <Link to={`/nest/${profile.affiliation}`}>
            <h4 className={styles.avatar}>{communityTitle}</h4>
          </Link>
          <DateText value={time} />
        </div>
      ) : (
        <div className={styles.profile}>
          <img src={pf} className={styles.img} alt="유저 이미지" />
          <h4 className={styles.avatar}>{profile.writer}</h4>
          <DateText value={time} />
        </div>
      )}
      <div className={styles.etc}>
        <img src={etc} alt="기타 기능" />
      </div>
    </div>
  );
}

export default ShowPostProfile;
