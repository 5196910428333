import React, { useState } from "react";
import styles from "./MembershipPage.module.css";

function MembershipPage() {
  const benefits = [
    "광고 없는 브라우징 경험",
    "유료 이용자 전용 커뮤니티 액세스",
    "전문가방 월 3회 체험 (1일 기간)",
    "전자책 무제한 열람",
    "독점 디지털 뱃지",
    "커뮤니티 이벤트 우선 참가권",
    "맞춤형 콘텐츠 추천",
    "월간 온라인 멘토링 세션",
    "연간 오프라인 네트워킹 파티 초대",
    "커뮤니티 의사결정 투표권",
  ];

  return (
    <div className={styles.container}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <div className={styles.logoContainer}>
            <svg
              className={styles.logo}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 18.5L9 22H15L12 18.5Z" />
              <circle cx="12" cy="10" r="8" />
            </svg>
            <h2 className={styles.title}>네스톡 프리미엄</h2>
          </div>
          <p className={styles.subtitle}>네스톡과 함께 투자의 날개를 펴세요!</p>
        </div>
        <div className={styles.modalBody}>
          <h3 className={styles.benefitsTitle}>프리미엄 혜택:</h3>
          <ul className={styles.benefitsList}>
            {benefits.map((benefit, index) => (
              <li key={index} className={styles.benefitItem}>
                <span className={styles.checkmark}>✓</span>
                {benefit}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.modalFooter}>
          <button className={styles.subscribeButton}>
            월 구독 - 5,000원/월
          </button>
          <button className={styles.subscribeButton}>
            연간 구독 - 50,000원/년 (2개월 무료)
          </button>
        </div>
      </div>
    </div>
  );
}

export default MembershipPage;
