// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReadingOption_tabContainer__tDg38 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ReadingOption_tabButton__7XC4P {
  width: 11vw;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
}

.ReadingOption_inactiveTab__492OP:hover {
  background-color: rgb(230, 230, 230);
}

.ReadingOption_activeTab__9wVRx {
  background-color: rgb(200, 200, 200);
}
`, "",{"version":3,"sources":["webpack://./src/components/ReadingOption.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".tabContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n}\n\n.tabButton {\n  width: 11vw;\n  height: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 16px;\n  padding: 5px;\n  border-radius: 10px;\n  text-align: center;\n}\n\n.inactiveTab:hover {\n  background-color: rgb(230, 230, 230);\n}\n\n.activeTab {\n  background-color: rgb(200, 200, 200);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": `ReadingOption_tabContainer__tDg38`,
	"tabButton": `ReadingOption_tabButton__7XC4P`,
	"inactiveTab": `ReadingOption_inactiveTab__492OP`,
	"activeTab": `ReadingOption_activeTab__9wVRx`
};
export default ___CSS_LOADER_EXPORT___;
