import { getPosts } from "../api";
import ShowPost from "./ShowPost";
import styles from "./PostList.module.css";

function PostList({ isCommunity = false, showCommunity = true, keyword = "" }) {
  const posts = getPosts(isCommunity, keyword);
  console.log(posts);

  return (
    <div>
      {posts.map((post) => (
        <div key={post.id} className={styles.innerBox}>
          <div className={styles.post}>
            <ShowPost value={post} showCommunity={showCommunity} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default PostList;
