import { useLocation } from "react-router-dom";
import { getCommunityTitle, getPost } from "../api";
import ShowPost from "../components/ShowPost";
import ShowPostProfile from "../components/ShowPostProfile";
import InteractionMenu from "../components/InteractionMenu";
import styles from "./PostPage.module.css";
import arrow from "../assets/icon--arrow-to-left.svg";

function ShowComment({ info }) {
  const { content, writer, createdAt } = info;
  return (
    <div className={styles.comment}>
      <ShowPostProfile
        showCommunity={false}
        profile={{ writer }}
        time={createdAt}
      />
      <p>{content}</p>
      <InteractionMenu recommendCount="1" commentCount="2" isComment={true} />
    </div>
  );
}

function PostPage() {
  const location = useLocation();
  const postId = location.pathname.split("/")[4];
  const post = getPost(postId);
  const communityTitle = getCommunityTitle(post.affiliation);
  const comments = post.comment;
  return (
    <div>
      <div className={styles.communityBox}>
        <div className={styles.imgBox}>
          <img src={arrow} alt="돌아가기" />
        </div>
        <h4 className={styles.community}>{communityTitle} 커뮤니티</h4>
      </div>
      <div className={styles.postBox}>
        <ShowPost showCommunity={false} value={post} />
      </div>
      <div className={styles.commentBox}>
        <h2>댓글 {comments.length}개</h2>
        {comments.map(({ id, content, writer, createdAt }) => (
          <ShowComment key={id} info={{ content, writer, createdAt }} />
        ))}
      </div>
    </div>
  );
}

export default PostPage;
