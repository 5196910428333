import styles from "../components/SearchBar.module.css";
import searchIcon from "../assets/search.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetSearchParams } from "../contexts/SearchContext";
import { useEffect, useState, useRef } from "react";
import AutoComplete from "./AutoComplete";

function SearchBar() {
  const [isOpen, setIsOpen] = useState(false);
  const autoComplete = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const setSearch = useSetSearchParams();
  const [keyword, setKeyword] = useState("");
  const className = [styles.blurredInput, styles.blurredButton];

  const getSearchHistory = () => {
    const historyData = localStorage.getItem("searchHistory");
    return historyData ? JSON.parse(historyData) : [];
  };

  const [history, setHistory] = useState(getSearchHistory);

  function editSearchHistory(method, keyword) {
    setHistory((prevHistory) => {
      console.log(keyword);
      console.log(prevHistory);
      const filtered = prevHistory.filter((record) => record !== keyword);
      console.log(filtered);
      switch (method) {
        case "add":
          console.log("added");
          return filtered
            ? [keyword, ...filtered].splice(0, 20)
            : [keyword, ...prevHistory].splice(0, 20);
        case "delete":
          console.log("deleted");
          return filtered;
        default:
          console.log("edit method 입력 오류");
      }
      return prevHistory;
    });
    console.log(history);
  }

  const handleKeywordChange = (e) => setKeyword(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearch((prevSearch) =>
      keyword
        ? {
            ...prevSearch,
            keyword,
          }
        : {}
    );
    document.getElementById("searchInput").blur();
    editSearchHistory("add", keyword);
    navigate("/search");
  };

  useEffect(() => {
    setKeyword("");
  }, [location]);

  useEffect(() => {
    localStorage.setItem("searchHistory", JSON.stringify(history));
  }, [history]);

  useEffect(() => {
    console.log(isOpen);
  }, [isOpen]);

  return (
    <div className={styles.searchBar} onClick={() => setIsOpen(true)}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <input
          id="searchInput"
          name="keyword"
          value={keyword}
          onChange={handleKeywordChange}
          placeholder="검색"
          autoComplete="off"
          className={className[0]}
        ></input>
        <button type="submit" className={className[1]}>
          <img src={searchIcon} alt="검색" />
        </button>
      </form>
      {isOpen && (
        <div
          className={styles.autoCompleteContainer}
          ref={autoComplete}
          onClick={(e) => {
            e.stopPropagation();
            if (e.target === autoComplete.current) {
              setIsOpen(false);
            }
          }}
        >
          <AutoComplete
            keyword={keyword}
            aboutHistory={{ history, editSearchHistory }}
          />
        </div>
      )}
    </div>
  );
}

export default SearchBar;
