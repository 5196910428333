// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 960px) {
  .PageBox_pageBox__2j37I {
    width: 80vw;
  }
}

@media screen and (min-width: 960px) and (max-width: 1200px) {
  .PageBox_pageBox__2j37I {
    width: 55vw;
  }
}

@media screen and (min-width: 1200px) {
  .PageBox_pageBox__2j37I {
    width: 50vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/PageBox.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":["@media screen and (max-width: 960px) {\n  .pageBox {\n    width: 80vw;\n  }\n}\n\n@media screen and (min-width: 960px) and (max-width: 1200px) {\n  .pageBox {\n    width: 55vw;\n  }\n}\n\n@media screen and (min-width: 1200px) {\n  .pageBox {\n    width: 50vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageBox": `PageBox_pageBox__2j37I`
};
export default ___CSS_LOADER_EXPORT___;
