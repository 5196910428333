import Nav from "./components/Nav";
import styles from "./components/App.module.css";
import "./components/App.font.css";
import { Outlet, useNavigate } from "react-router-dom";
import LeftSideBar from "./components/LeftSideBar";
import RightSideBar from "./components/RightSideBar";
import { SearchProvider } from "./contexts/SearchContext";
import { AuthProvider, useAuth } from "./contexts/AuthProvider";
import { useEffect } from "react";

function App() {
  const { isOnline } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    console.log(isOnline);
    if (!isOnline) {
      navigate("/");
    }
  }, [isOnline]);
  return (
    <div className={styles.container}>
      <SearchProvider>
        <AuthProvider>
          <Nav className={styles.nav} />
          <div className={styles.body}>
            <LeftSideBar className={styles.leftSideBar} />
            <div className={styles.content}>
              <Outlet className={styles.mainContent} />
              <RightSideBar className={styles.rightsideBar} />
            </div>
          </div>
        </AuthProvider>
      </SearchProvider>
    </div>
  );
}

export default App;
