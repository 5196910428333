import styles from "./MentorListPage.module.css";
import pf from "../assets/logo.png";
import { useNavigate } from "react-router-dom";

function MentorListPage() {
  const navigate = useNavigate();

  const mentors = [
    {
      name: "임요환",
      slug: "lim777",
      members: "353",
      posts: "51",
    },
    {
      name: "홍진호",
      slug: "hong",
      members: "222",
      posts: "22",
    },
    {
      name: "권효준",
      slug: "kwon",
      members: "0",
      posts: "102",
    },
  ];

  return (
    <div className={styles.specialists}>
      <h1>전문가 목록</h1>
      <div className={styles.list}>
        {mentors.map((mentor, idx) => (
          <div
            key={idx}
            className={styles.container}
            onClick={() => {
              console.log("clicked");
              navigate(`./${mentor.slug}`);
            }}
          >
            <img src={pf} alt="프로필" className={styles.img} />
            <div className={styles.textContainer}>
              <h2>{mentor.name}</h2>
              <div className={styles.detailsContainer}>
                <p>멤버 {mentor.members}명</p>
                <p>게시물 {mentor.posts}개</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MentorListPage;
