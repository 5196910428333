import { Link } from "react-router-dom";
import styles from "./ShowCommunityProfile.module.css";
import pf from "../assets/logo.png";
import pfbg from "../assets/backgroundWhite.png";

function ShowCommunityProfile({ title, pfbgImg = pfbg, pfImg = pf, location }) {
  return (
    <>
      <div
        alt="프로필 배경 이미지"
        style={{
          backgroundColor: "#f0f0f0",
        }}
        className={styles.background}
      />
      <div className={styles.profileContainer}>
        <img
          src={
            "https://file.alphasquare.co.kr/media/images/stock_logo/kr/005930.png"
          }
          alt="프로필 이미지"
          className={styles.pfImg}
        />
        <div className={styles.profile}>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.buttonContainer}>
            <button className={styles.button}>
              <h2>팔로우</h2>
            </button>
            <Link
              style={{
                width: "fit-content",
                height: "33px",
                padding: "7px",
                border: "1px solid #0f0f0f",
                borderRadius: "15px",
                display: "flex",
                alignItems: "center",
                gap: "2px",
                textDecoration: "none",
              }}
              to={`${location}/posting`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
              >
                <path
                  d="M12 4v16M4 12h16"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeinejoin="round"
                  fill="none"
                />
              </svg>
              <h2>포스팅</h2>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShowCommunityProfile;
