import RecommendCount from "./RecommendCount";
import styles from "./InteractionMenu.module.css";
import commmentIcon from "../assets/icon--comment.svg";
import shareIcon from "../assets/icon--share.svg";
import classNames from "classnames/bind";
const bindCN = classNames.bind(styles);

function InteractionMenu({ recommendCount, commentCount, isComment = false }) {
  return (
    <>
      {isComment ? (
        <div className={styles.menu}>
          <RecommendCount recommendCount={recommendCount} />
          <div className={styles.commented}>
            <img src={commmentIcon} alt="댓글수" />
          </div>
        </div>
      ) : (
        <div className={bindCN(styles.menu, styles.notComment)}>
          <RecommendCount recommendCount={recommendCount} />
          <div className={styles.commented}>
            <img src={commmentIcon} alt="댓글수" />
            <p>{commentCount}</p>
          </div>
          <div className={styles.share}>
            <img src={shareIcon} alt="공유하기" />
          </div>
        </div>
      )}
    </>
  );
}

export default InteractionMenu;
