import { Link } from "react-router-dom";
import Container from "./Container";
import personIcon from "../assets/person.png";
import SearchBar from "./SearchBar";
import Alarm from "./Alarm";
import Login from "./Login";
import logo from "../assets/logo.png";
import logoName from "../assets/logo-name.png";
import styles from "./Nav.module.css";
import classNames from "classnames/bind";
import LeftSideBar from "./LeftSideBar";
import { useState, useRef } from "react";
import { useAuth } from "../contexts/AuthProvider";
const bindCN = classNames.bind(styles);

function Nav() {
  const [leftOpen, setLeftOpen] = useState(false);
  const leftBar = useRef();
  const { isOnline } = useAuth();
  return (
    <div className={styles.nav}>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          {leftOpen && (
            <div
              className={styles.leftBar}
              ref={leftBar}
              onClick={(e) => {
                e.stopPropagation();
                if (e.target === leftBar.current) {
                  setLeftOpen(false);
                }
              }}
            >
              <LeftSideBar isOpen={leftOpen} />
            </div>
          )}
          <div
            className={styles.leftBarIcon}
            onClick={() => {
              console.log("click");
              setLeftOpen(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="30"
              height="30"
            >
              <path
                d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"
                fill="#000000"
              />
            </svg>
          </div>
          <Link to="/">
            <img
              className={bindCN(styles.image, styles.mobileLogo)}
              src={logo}
              alt="Nestock Logo"
            />
            <img
              className={bindCN(styles.image, styles.notMobileLogo)}
              src={logoName}
              alt="Nestock Logo"
            />
          </Link>
        </div>
        <SearchBar className={styles.searchBar} />
        <div className={styles.menuBox}>
          {isOnline ? (
            <>
              <Alarm />
              <button className={styles.iconButton}>
                <img src={personIcon} alt="유저 메뉴" />
              </button>
            </>
          ) : (
            <Login />
          )}
        </div>
      </div>
    </div>
  );
}

export default Nav;
