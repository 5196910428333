import { createContext, useContext, useEffect, useState } from "react";
import { login } from "../api/AuthApi";

const initial_user = {
  id: null,
  status: null,
  username: null,
  password: null,
  email: null,
  role: null,
  social_provider: null,
  social_id: null,
  created_at: null,
  updated_at: null,
};

const AuthContext = createContext({
  user: initial_user,
  isOnline: false,
  setLogIn: () => {},
  setLogOut: () => {},
  setUpdate: () => {},
});

export function AuthProvider({ children }) {
  const [user, setUser] = useState(initial_user);
  const [isOnline, setIsOnline] = useState(false);

  async function setLogIn(loginData) {
    const { code, msg, userData } = await login(loginData);
    if (code < 300) {
      setUser((prevData) => ({
        ...prevData,
        userData,
      }));
      setIsOnline(true);
      console.log("login success");
    } else {
      console.log(code, msg);
    }
  }

  async function setLogOut() {
    setUser((prevData) => ({
      ...prevData,
      initial_user,
    }));
    setIsOnline(false);
  }

  // async function setUpdate() {
  //   //미완
  //   //update 함수가 완성되어야 사용 가능
  //   //update 함수 = 프로필 편집
  //   const { code, msg, userData } = await update(updateData);
  //   if (200 <= code < 300) {
  //     setUser((prevData) => ({
  //       ...prevData,
  //       userData,
  //     }));
  //   } else {
  //     console.log(code, msg);
  //   }
  // }

  // useEffect(() => {
  //   //첫 렌더링시 (새로고침시 포함) 로그인 인증된 사용자의 정보를 조회
  //   //access 쿠키 이용
  //   getMe();
  // }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isOnline,
        setLogIn,
        setLogOut,
        // setUpdate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("반드시 AuthProvider 안에서 사용해야 합니다.");
  }

  return context;
}
