import styles from "./ReadingOption.module.css";
import classNames from "classnames/bind";
const bindCN = classNames.bind(styles);

function ReadingOption({ isUser, activeTab, handleClick }) {
  const userTabs = [
    { id: "entire", name: "전체" },
    { id: "posts", name: "게시물" },
    { id: "comments", name: "댓글" },
    { id: "badge", name: "뱃지" },
  ];

  const mentorTabs = [
    { id: "entire", name: "전체" },
    { id: "infoShare", name: "정보공유" },
    { id: "povShare", name: "관점공유" },
    { id: "dailyLife", name: "일상" },
  ];

  if (!isUser) {
    return (
      <div className={styles.tabContainer}>
        {mentorTabs.map((tab) => (
          <button
            key={tab.id}
            className={bindCN(
              styles.tabButton,
              styles[`${activeTab === tab.id ? "activeTab" : "inactiveTab"}`]
            )}
            onClick={() => handleClick(tab.id)}
          >
            {tab.name}
          </button>
        ))}
      </div>
    );
  } else {
    return (
      <div className={styles.tabContainer}>
        {userTabs.map((tab) => (
          <button
            key={tab.id}
            className={bindCN(
              styles.tabButton,
              styles[`${activeTab === tab.id ? "activeTab" : "inactiveTab"}`]
            )}
            onClick={() => handleClick(tab.id)}
          >
            {tab.name}
          </button>
        ))}
      </div>
    );
  }
}

export default ReadingOption;
