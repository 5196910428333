import mock from "./mock.json";
const { communities, posts, users } = mock;

export function getUserInformation(userID) {
  const theUser = users.find((user) => user.id === userID);

  if (!theUser) {
    return {
      name: "error",
      followers: "error",
      followings: "error",
      point: "error",
      badges: "error",
    };
  }
  return {
    name: theUser.name,
    followers: theUser.followers,
    followings: theUser.followings,
    point: theUser.point,
    badges: theUser.badges,
  };
}

export function getCommunityInformation(communitySlug) {
  const theCommunity = communities.find(
    (community) => community.slug === communitySlug
  );

  if (!theCommunity) {
    return {
      title: "error",
      pfImg: "error",
      pfbgImg: "error",
    };
  }
  return {
    title: theCommunity.title,
    pfImg: theCommunity.pfImg,
    pfbgImg: theCommunity.pfbgImg,
  };
}

export function getCommunityTitle(affiliation) {
  const theCommunity = communities.find(
    (community) => community.slug === affiliation
  );

  return theCommunity?.title;
}

export function getCoumminityNames(keyword) {
  if (!keyword) return;

  const lowered = keyword.toLowerCase();

  const allItems = communities.filter(({ title }) =>
    title.toLowerCase().includes(lowered)
  );

  return allItems.slice(0, 6);
}

export function getPost(postId) {
  const thePost = posts.find((post) => post.id === postId);

  return thePost;
}

export function getPosts(isCommunity, keyword) {
  console.log(isCommunity, keyword);
  if (!keyword) return posts;

  if (isCommunity) {
    return posts.filter((post) => post.affiliation === keyword);
  } else {
    const lowered = keyword.toLowerCase();
    const titleInclude = posts.filter(({ title }) =>
      title.toLowerCase().includes(lowered)
    );
    const contentInclude = posts.filter(({ content }) =>
      content.toLowerCase().includes(lowered)
    );

    return [...titleInclude, ...contentInclude];
  }
}
