const BASE_URL = "https://dev.api.stockmanual.net/api";
const USER_URL = `${BASE_URL}/user`;
const AUTH_URL = `${BASE_URL}/auth`;
const CALENDAR_URL = `${BASE_URL}/calendar`;
const COMMUNITY_URL = `${BASE_URL}/community`;
const POST_URL = `${BASE_URL}/post`;

function formingData(apiData) {
  /////apiData는 반드시 객체여야함
  const formData = new FormData();
  for (const dataKey in apiData) {
    formData.append(`${dataKey}`, apiData[dataKey]);
  }
  return formData;
}

function settingParams() {
  return;
}

export {
  USER_URL,
  AUTH_URL,
  CALENDAR_URL,
  COMMUNITY_URL,
  POST_URL,
  formingData,
};
