import React, { useState } from "react";
import TradeForm from "../components/TradeForm";
import TradeList from "../components/TradeList";
import styles from "./TradeMemoPage.module.css";

const TradeMemoPage = () => {
  const [trades, setTrades] = useState([]);

  const addTrade = (newTrade) => {
    setTrades((prevTrades) => [newTrade, ...prevTrades]);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>내 투자 복기 시스템</h1>
      <div className={styles.grid}>
        <TradeForm onAddTrade={addTrade} />
        <TradeList trades={trades} />
      </div>
    </div>
  );
};

export default TradeMemoPage;
