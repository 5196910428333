import { useSearchParams } from "../contexts/SearchContext";
import PostList from "../components/PostList";
import pageStyle from "../components/ui/PageBox.module.css";

function PostListPage() {
  const search = useSearchParams();

  return (
    <div className={pageStyle.pageBox}>
      <PostList keyword={search?.keyword} />
    </div>
  );
}

export default PostListPage;
