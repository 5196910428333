import PostList from "../components/PostList";
import badge1 from "../assets/badges/badge--short.jpg";
import badge2 from "../assets/badges/badge--expert.jpg";
import badge3 from "../assets/badges/badge--dividend.jpg";
import badge4 from "../assets/badges/badge--trade-log.jpg";
import badge5 from "../assets/badges/badge--only-car.jpg";
import badge6 from "../assets/badges/badge--billionair.jpg";
import badge7 from "../assets/badges/badge--gain500pct.jpg";
import badge8 from "../assets/badges/badge--gain100pct.jpg";
import badge9 from "../assets/badges/badge--top5pct.jpg";
import badge10 from "../assets/badges/badge--only-medicine.jpg";
import badge11 from "../assets/badges/badge--scalping.jpg";
import styles from "./TabContent.module.css";

function TabContent({ activeTab, badges }) {
  const entireBadges = [
    {
      idx: 1,
      name: "short",
      src: badge1,
    },
    {
      idx: 2,
      name: "expert",
      src: badge2,
    },
    {
      idx: 3,
      name: "dividend",
      src: badge3,
    },
    {
      idx: 4,
      name: "trade-log",
      src: badge4,
    },
    {
      idx: 5,
      name: "only-car",
      src: badge5,
    },
    {
      idx: 6,
      name: "billionair",
      src: badge6,
    },
    {
      idx: 7,
      name: "gain500pp",
      src: badge7,
    },
    {
      idx: 8,
      name: "gain100pp",
      src: badge8,
    },
    {
      idx: 9,
      name: "top5pp",
      src: badge9,
    },
    {
      idx: 10,
      name: "only-medicine",
      src: badge10,
    },
    {
      idx: 11,
      name: "scalping",
      src: badge11,
    },
  ];

  switch (activeTab) {
    case "entire":
      return <PostList />;

    case "badge":
      const havingBadges = [];
      const notHavingBadges = [];
      entireBadges.forEach((badge) => {
        if (badges.includes(badge.name)) {
          havingBadges.push(badge);
        } else {
          notHavingBadges.push(badge);
        }
      });

      return (
        <>
          <h2 className={styles.sectionTitle}>보유 중인 뱃지</h2>
          <div className={styles.havingBadge}>
            {havingBadges.map((badge) => (
              <img
                key={badge.idx}
                alt={badge.name}
                src={badge.src}
                className={styles.badge}
              />
            ))}
          </div>
          <h2 className={styles.sectionTitle}>아직 얻지 못한 뱃지</h2>
          <div className={styles.notHavingBadge}>
            <div className={styles.locked}></div>
            <div className={styles.badges}>
              {notHavingBadges.map((badge) => (
                <img
                  key={badge.idx}
                  alt={badge.name}
                  src={badge.src}
                  className={styles.badge}
                />
              ))}
            </div>
          </div>
        </>
      );
    // ... (다른 case들은 이전과 동일)
    default:
      return null;
  }

  // return <div className={styles.mainContent}>{content}</div>;
}

export default TabContent;
