import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import PostListPage from "./pages/PostListPage";
import CommunityPage from "./pages/CommunityPage";
import MentorListPage from "./pages/MentorListPage";
import MentorPage from "./pages/MentorPage";
import PostPage from "./pages/PostPage";
import PostingPage from "./pages/PostingPage";
import CalenderPage from "./pages/CalenderPage";
import TradeMemoPage from "./pages/TradeMemoPage";
import CouncelPage from "./pages/CouncelPage";
import MembershipPage from "./pages/MembershipPage";
import SignInPage from "./pages/SignInPage";
// import UserPage from "./pages/UserPage";
import UserPage from "./pages/UserPageBeta";
import NotFoundPage from "./pages/NotFoundPage";

function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<PostListPage />} />
          <Route path="search" element={<PostListPage />} />
          <Route path="nest">
            <Route index element={<NotFoundPage />} />
            <Route path=":communitySlug">
              <Route index element={<CommunityPage />} />
              <Route path="post">
                <Route index element={<NotFoundPage />} />
                <Route path=":postId" element={<PostPage />} />
              </Route>
              <Route path="posting" element={<PostingPage />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path="mentor">
            <Route index element={<MentorListPage />} />
            <Route path=":mentorSlug">
              <Route index element={<MentorPage />} />
              {/* <Route path="post">
                <Route index element={<NotFoundPage />} />
                <Route path=":postId" element={<PostPage />} />
              </Route>
              <Route path="posting" element={<PostingPage />} /> */}
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path="calender" element={<CalenderPage />} />
          <Route path="tradememo" element={<TradeMemoPage />} />
          <Route path="councel" element={<CouncelPage />} />
          <Route path="membership" element={<MembershipPage />} />
          <Route path="signin" element={<SignInPage />} />
          {/* <Route path="user">
            <Route index element={<UserPage />} />
          </Route> */}
          <Route path="user">
            <Route index element={<NotFoundPage />} />
            <Route path=":userID">
              <Route index element={<UserPage />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Main;
